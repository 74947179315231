import { Suspense, lazy, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PageLoader from "../Components/PageLoader";
import Main from "./Main";

export const Router = () => {
  const location = useLocation();

  const scrollToTop = (url) => {
    if (!url.includes("#")) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToTop(window.location.href);
  }, [location]);

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <Suspense fallback={<PageLoader />}>
            <Main />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Router;
