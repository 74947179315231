import grey from "@mui/material/colors/grey";

export const light = () => ({
  primary: {
    main: "#21396D",
    background: "#A6C1FF",
    default: "#21396D",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#21396D",
    background: "#333333",
    default: "#2E4CEB",
    contrastText: "#fff",
  },
  divider: "#000000",
  text: {
    primary: "#1E1E1E",
    secondary: "#333333",
    border: grey[200],
    icon: grey[500],
    tertiary: "#1C5F5D",
    info: "#00CECE",
  },
  background: {
    default: "#fff",
    paper: "#fff",
    menu: "#fff",
    main: "#fff",
    secondary: "#FAFAFA",
    black: "#202020",
    yellow: "#F0CD12",
    message: "#EAB3240D",
  },
  appBar: {
    background: "#fff",
  },
});

export const lightBaseline = {
  scrollbarColor: "#6b6b6b #202225",
  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "#fff",
    width: 1,
    height: 8,
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 8,
    backgroundColor: "#B1B1B1",
    minHeight: 8,
    border: "3px solid #B1B1B1",
  },
  "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
    backgroundColor: "#B1B1B1",
  },
  "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
    backgroundColor: "#B1B1B1",
  },
  "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#B1B1B1",
  },
  "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
    backgroundColor: "transparent",
  },
};
