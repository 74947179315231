import {
  Box,
  Button,
  Divider,
  Menu,
  Tab,
  Tabs,
  Typography,
  Avatar,
  IconButton,
  Badge,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  ExpandMore,
  SystemUpdateAlt,
  Logout,
  Language,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import DropDownMenu from "../Components/DropDownMenu/index.jsx";
import { egFlag, logo, usFlag } from "../assets/index.js";

export default function Navbar() {
  const { t, i18n } = useTranslation(["global"]);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isTop, setIsTop] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElM, setAnchorElM] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const path = location.pathname;
  const openM = Boolean(anchorElM);

  const tabs = [
    { name: "home", value: "/" },
    {
      name: "about_us",
      value: "aboutUsSection",
      url: "/",
      id: "#aboutUsSection",
    },
    {
      name: "our_solutions",
      value: "ourSolutionsSection",
      url: "/",
      id: "#ourSolutionsSection",
    },
    { name: "why_neo", value: "/why-us" },
    { name: "products", value: "/products" },
    // { name: "pricing", value: "/pricing" },
    // { name: "contact", value: "/contact-us" },
  ];

  const handleChange = (e, v) => {
    const tab = tabs.find((tab) => tab.value === v);

    if (tab) {
      if (tab?.value?.includes("/")) {
        navigate(tab?.value);
      } else {
        navigate(tab?.url);
        setTimeout(() => {
          const element = document?.getElementById(tab?.id);
          if (element) {
            element?.scrollIntoView({ behavior: "smooth" });
          }
        }, 1000);
      }
    }
  };

  const changeLanguage = useCallback(() => {
    let lang = i18n.language;
    let newLang = "en";
    if (lang === "en") {
      newLang = "ar";
    }

    localStorage.setItem("lng", newLang);
    window?.location?.reload();
  }, [i18n]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsTop(scrollTop === 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpenMenu = (event) => {
    setAnchorElM(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElM(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleLogout = () => {
    handleClose();
  };

  const handleNavigate = (link) => {
    navigate(link);
    handleClose();
  };

  const profileDropDown = [
    {
      icon: <SystemUpdateAlt sx={{ transition: "color 0.5s ease-in-out" }} />,
      text: t("update_profile"),
      function: () => handleNavigate("/settings?tab=editProfile"),
    },
    // {
    //   icon: <ChangeCircle sx={{ transition: "color 0.5s ease-in-out" }} />,
    //   text: t("Change Information"),
    // },
    {
      icon: (
        <Logout sx={{ transition: "color 0.5s ease-in-out", color: "red" }} />
      ),
      text: t("logout"),
      function: handleLogout,
    },
  ];

  return (
    <Box
      sx={{
        maxWidth: "100vw",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        p: "0.5em 2em",
        position: "sticky",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9,
        boxShadow: "0px 10px 20px rgba(117, 117, 117, 0.18)",
        gap: "1em",
        bgcolor: "secondary.main",
      }}
    >
      <Box component={Link} to={"/"}>
        <img src={logo} alt="" style={{ height: "100%", cursor: "pointer" }} />
      </Box>

      {/*PC View*/}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: "1em",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                textTransform: "uppercase",
                fontWeight: "400",
                color: "#FFF",
              }}
            >
              00201033377399
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "1em",
                "& Button, & a": {
                  color: "background.paper",
                  "&:hover": {
                    color: "primary.main",
                    bgcolor: "transparent",
                  },
                  transition:
                    "color 0.3s ease-in-out, background-color 0.3s ease-in-out",
                },
              }}
            >
              <Button
                variant="text"
                onClick={changeLanguage}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5em",
                  "& > *": {
                    fill: "#FFF",
                  },
                }}
              >
                {i18n.language === "en" ? (
                  <img src={egFlag} alt="USA Flag" style={{ height: "20px" }} />
                ) : (
                  <img src={usFlag} alt="EGY Flag" style={{ height: "20px" }} />
                )}
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 400,
                    color: "#FFF",
                  }}
                >
                  {i18n.language === "en" ? "عربي" : "English"}
                </Typography>
              </Button>
            </Box>

            <Button
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "1em",
                color: "#FFF",
                bgcolor: "#13205D",
              }}
              variant="contained"
            >
              {t("free_trial")}
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1em",
            width: "100%",
          }}
        >
          <Tabs
            value={path}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              "& .MuiTabs-indicator": {
                // display: "none",
                bgcolor: "#A6C1FF",
              },
              "& .MuiTab-root.Mui-selected": {
                color: "#FFF",
              },
              "& .MuiTab-root": {
                color: "#D1D1D6",
                transition: "color 0.3s ease-in-out",
                "&:hover": {
                  color: "#FFF",
                },
              },
              "& .MuiTabs-scrollButtons": {
                color: "background.paper",
              },
            }}
          >
            {tabs?.map((item, index) => (
              <Tab
                key={index}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: "400" }}>
                      {t(item?.name)}
                    </Typography>
                  </Box>
                }
                value={item?.value}
              />
            ))}
          </Tabs>

          <Button
            variant="text"
            sx={{ color: "#FFF", fontWeight: 600 }}
            component={Link}
            to={"https://mydashboard.neotogar.com/login"}
          >
            {t("login")}
          </Button>
        </Box>
      </Box>
      {/*PC View*/}

      {/*Mobile View*/}
      <Button
        onClick={handleOpenMenu}
        size="small"
        sx={{
          height: "fit-content",
          width: "fit-content",
          p: "0.5em",
          minWidth: "fit-content",
          display: { xs: "inline-flex", md: "none" },
        }}
        aria-controls={openM ? "header-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openM ? "true" : undefined}
      >
        <MenuIcon sx={{ color: "#FFF" }} />
      </Button>

      <Menu
        disableScrollLock={true}
        anchorEl={anchorElM}
        open={openM}
        onClose={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "scroll",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            p: "0.5em",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Tabs
          value={path}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          orientation="vertical"
          sx={{
            display: "flex",
            "& .MuiTabs-indicator": {
              display: "none",
            },
            "& .MuiTab-root.Mui-selected": {
              color: "secondary.main",
            },
            "& .MuiTab-root": {
              color: "gray",
              transition: "color 0.3s ease-in-out",
              "&:hover": {
                color: "secondary.main",
              },
            },
            "& .MuiTabs-scrollButtons": {
              color: "background.paper",
            },
          }}
        >
          {tabs?.map((item, index) => (
            <Tab
              key={index}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: "400" }}>
                    {t(item?.name)}
                  </Typography>
                </Box>
              }
              value={item?.value}
            />
          ))}
        </Tabs>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1em",
            "& Button, & a": {
              color: "text.primary",
              "&:hover": {
                color: "primary.main",
                bgcolor: "transparent",
              },
              transition:
                "color 0.3s ease-in-out, background-color 0.3s ease-in-out",
            },
            p: "1em 1em 0 1em",
            width: "100%",
          }}
        >
          <Button
            variant="text"
            onClick={changeLanguage}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5em",
              "& > *": {
                fill: "#FFF",
              },
            }}
          >
            {i18n.language === "en" ? (
              <img src={egFlag} alt="USA Flag" style={{ height: "20px" }} />
            ) : (
              <img src={usFlag} alt="EGY Flag" style={{ height: "20px" }} />
            )}
            <Typography
              variant="body1"
              sx={{
                fontWeight: 400,
                color: "text.secondary",
              }}
            >
              {i18n.language === "en" ? "عربي" : "English"}
            </Typography>
          </Button>

          <Button
            variant="text"
            sx={{ color: "text.primary", fontWeight: 600 }}
            component={Link}
            to={"https://mydashboard.neotogar.com/login"}
          >
            {t("login")}
          </Button>
          {/* <Button
              sx={{
                p: 0,
                display: "flex",
                justifyContent: "start",
                gap: "1em",
                "& > *": {
                  color: "red",
                },
                width: "100%",
              }}
              onClick={handleLogout}
            >
              <Logout />
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                {t("logout")}
              </Typography>
            </Button> */}
        </Box>
      </Menu>
      {/*Mobile View*/}
    </Box>
  );
}
