import { lazy, Suspense, useMemo } from "react";
import Navbar from "../Layout/Navbar";
import Footer from "../Layout/Footer";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import PageLoader from "../Components/PageLoader";
import { Box, Typography } from "@mui/material";

const Home = lazy(() => import("../Pages/Home/index"));
const Products = lazy(() => import("../Pages/Products/index"));
const Pricing = lazy(() => import("../Pages/Pricing/index"));
const ContactUs = lazy(() => import("../Pages/ContactUs/index"));
const Login = lazy(() => import("../Pages/Auth/Login/index"));
const Register = lazy(() => import("../Pages/Auth/Register/index"));
const WhyUs = lazy(() => import("../Pages/WhyUs/index"));
const ProductDetails = lazy(() =>
  import("../Pages/Products/ProductDetails/index")
);

const Main = () => {
  // const isAuthenticated = useSelector((s) => s?.auth?.isAuthenticated);

  // let currentLocationForRedirect = useMemo(() => {
  //   return window.location.href?.split(window?.location?.origin);
  // }, []);
  // if (!isAuthenticated)
  //   return (
  //     <Navigate
  //       to="/login"
  //       state={{
  //         currentLocationForRedirect: currentLocationForRedirect
  //           ? currentLocationForRedirect[1]
  //           : null,
  //       }}
  //       replace
  //     />
  //   );
  return (
    <Box sx={{ overflow: "hidden" }}>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<PageLoader />}>
              <Home />
            </Suspense>
          }
        />

        <Route
          path="/products"
          element={
            <Suspense fallback={<PageLoader />}>
              <Products />
            </Suspense>
          }
        />

        <Route
          path="/pricing"
          element={
            <Suspense fallback={<PageLoader />}>
              <Pricing />
            </Suspense>
          }
        />

        <Route
          path="/why-us"
          element={
            <Suspense fallback={<PageLoader />}>
              <WhyUs />
            </Suspense>
          }
        />

        <Route
          path="/contact-us"
          element={
            <Suspense fallback={<PageLoader />}>
              <ContactUs />
            </Suspense>
          }
        />

        <Route
          path="/login"
          element={
            <Suspense fallback={<PageLoader />}>
              <Login />
            </Suspense>
          }
        />

        <Route
          path="/register"
          element={
            <Suspense fallback={<PageLoader />}>
              <Register />
            </Suspense>
          }
        />

        <Route
          path="/product-details/:id"
          element={
            <Suspense fallback={<PageLoader />}>
              <ProductDetails />
            </Suspense>
          }
        />

        <Route
          path="404"
          element={
            <Typography variant="h4" sx={{ p: "2em", textAlign: "center" }}>
              Not Found
            </Typography>
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <Footer />
    </Box>
  );
};

export default Main;
