import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import { Box } from "@mui/material";
import Loader from "./Components/Loader";

export const AppLoader = () => {
  return (
    <Box
      sx={{ height: "100vh" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      justifyItems="center"
    >
      <Loader />
    </Box>
  );
};

const Splash = () => {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
};

export default Splash;
