import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: {},
  //   middleware: (getDefaultMiddleware) => {
  //     return getDefaultMiddleware().concat(thunkMiddleware);
  //   },
  devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
