import { styled } from "@mui/material";
import { useSnackbar, SnackbarProvider } from "notistack";
import { MdNotifications } from "react-icons/md";
import { cutStrings } from "./cutStrings";
export const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-contentRoot {
    background-color: #25aae1;
    font-weight: bold;
    text-decoration: capitalize;
    max-width: 600;
    overflow: hidden;
  }
`;

export const snackIcons = {
  info: <MdNotifications size={25} />,
};

let useSnackbarRef;
export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};
export const snackStyle = {
  info: { backgroundColor: "yellow" },
};

const SnackUtils = {
  success(msg) {
    this.toast(msg, "success");
  },
  warning(msg) {
    this.toast(msg, "warning");
  },
  info(msg) {
    msg = cutStrings(msg, 100);
    this.toast(msg, "info");
  },
  error(msg) {
    this.toast(msg, "error");
  },
  toast(msg, variant = "default") {
    useSnackbarRef.enqueueSnackbar(msg, { variant });
  },
};
export default SnackUtils;
